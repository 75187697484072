import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/file.profile.api';
//import profile from '../../api/profiles/json.profile.api';

export default async (formData) => {
  const response = await f3tch('/vehicles/regi_upload/')
    .profile(profile({json:true}))
    .body(formData)
    .post();
  return response;
};
