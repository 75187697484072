import styled from 'styled-components';

// colors
import {grey, black} from '@matthahn/sally-ui/lib/libs/colors';

const Error = styled.div`
  width: 100%;
  background: ${grey};
  color: ${black};
  padding: 4px;
`;

export default Error;
