import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {v4} from 'uuid';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Api
import listVehiclesApi from '../../api/list.api.vehicle';
import uploadZipRegiApi from '../../api/uploadZipRegi.api.vehicle';

// Components
import VehiclesList from '../../components/VehiclesList/VehiclesList';
import NewVehicleModal from '../../components/NewVehicleModal/NewVehicleModal';
import UploadRegiZipModal from '../../components/VehicleRegiUploadModal/UploadRegiZipModal';
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// Containers
import NewVehicleContainer from '../NewVehicleContainer/NewVehicleContainer';

// Libs
import {lib} from '@matthahn/sally-ui';
import sortQuery from '../../../api/lib/sortQuery.lib.api';
import numberOfPages from '../../../lib/numberOfPages';
import vehicleState from '../../state';

// Route
import vehicleRoute from '../../pages/VehiclePage/route';

// Layout
import {clearUnsavedChanges} from '../../../layout/lib/unsavedChanges.lib.layout';

// Permissions
import createPermission from '../../permissions/create.permission.vehicle';

// States
import {stateList} from '../../state';


// Attributes
import {DATE} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import {dateTime, apiDateTime} from '@matthahn/sally-fw/lib/type';

var regiExpDate = attribute({
  type: DATE,
  attribute: 'regi_exp_date',
  display: dateTime,
  input: dateTime,
  api: apiDateTime,
  label: {
    default: 'Registartion Expiration Date',
    short: 'Expiration Date',
  },
});

// Alert
const {alert, notify} = lib;

class VehiclesListContainer extends Component {
  static propTypes = {
    unsavedChanges: PropTypes.bool,
    history: PropTypes.object,
  };

  static OPTIONS = {
    perPage: 50,
  };

  static DEFAULT_SORT = {
    key: 'accessed_at',
    direction: 'desc',
  };

  state = {
    tab: ['all'],
    results: 0,
    page: 1,
    loading: true,
    vehicles: [],
    sort: {...this.constructor.DEFAULT_SORT},
    newVehicleModalVisible: false,
    uploadRegiModalVisible: false,
    uploadingRegiZip: false,
    lastNewVehicleUpdate: null,
    regiExpDate: regiExpDate(''),
    regiZip: null,
    uploadErrors: []
  };

  componentDidMount() {
    this.mounted = true;
    this.getVehicles();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  mounted = false;
  apiID = v4();
  savingNewVehicle = false;

  tabs = () => [
    {
      value: 'all',
      label: 'All',
      query: {},
    },
    ...stateList.map((s) => ({
      value: s.key,
      label: s.label,
      query: {state__in: s.key},
    })),
  ];

  onTabChange = (tab) => {
    if (tab === 'all') {
      this.getVehicles({tab: ['all'], page: 1});
      return;
    }
    const filteredTab = (this.state.tab.includes(tab)
      ? [...this.state.tab].filter((t) => t !== tab)
      : [...this.state.tab, tab]
    ).filter((t) => t !== 'all');

    if (!filteredTab.length) {
      this.getVehicles({tab: ['all'], page: 1});
      return;
    }

    this.getVehicles({tab: filteredTab, page: 1});
  };

  onPage = (page) => this.getVehicles({page});

  onSearch = (search) => this.getVehicles({search});

  onSort = (sort) => this.getVehicles({sort});

  getTabData = (tab) => [...this.tabs()].find(({value}) => value === tab);

  pages = () =>
    numberOfPages(this.state.results, this.constructor.OPTIONS.perPage);

  getVehicles = async ({
    page = this.state.page,
    tab = this.state.tab,
    sort = this.state.sort,
  } = {}) => {
    const apiID = v4();
    this.apiID = apiID;
    const {perPage} = this.constructor.OPTIONS;
    this.setState({loading: true, vehicles: [], page, tab, sort});
    const query = {
      ...sortQuery(sort || this.constructor.DEFAULT_SORT),
      limit: perPage,
      offset: (page - 1) * perPage,
    };

    if (tab.length > 0 && !tab.includes('all')) query.state__in = tab.join(',');

    try {
      const {results, count} = await listVehiclesApi(query);
      if (this.apiID !== apiID || !this.mounted) return;
      this.setState({
        vehicles: results.map((veh) => ({
          ...veh,
          computedState: vehicleState(veh.state),
        })),
        loading: false,
        results: count,
      });
    } catch (error) {
      if (this.apiID !== apiID || !this.mounted) return;
      alert.info('The request could not be made');
      this.setState({loading: false, vehicles: [], results: 0});
    }
  };

  onVehicle = (id) => () => this.props.history.push(vehicleRoute(id));

  onNewVehicle = () =>
    this.setState({newVehicleModalVisible: true, lastNewVehicleUpdate: v4()});

  onRegiUpload = async () => {
    this.setState({uploadRegiModalVisible: true});
  }

  changeRegiZip = (key) => (value) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  onRegiUploadClose = async () => {
    this.setState({uploadRegiModalVisible: false, uploadingRegiZip: false, uploadErrors: [], regiZip: null, regiExpDate: regiExpDate('')});
  }

  uploadRegiZip = async () => {
    const {uploadingRegiZip, regiZip, regiExpDate} = this.state;
    if (uploadingRegiZip) return;
    if (!regiZip) return alert.warning('Select a ZIP file to upload');
    if (!regiExpDate.value())
      return alert.warning('Select Registration Expiration Date');

    this.setState({uploadingRegiZip: true});

    try {
      const formMeta = new FormData();
      formMeta.append('regi_zip_file', regiZip, regiZip.name);
      formMeta.append('expiration_date', regiExpDate.value());
      const response = await uploadZipRegiApi(formMeta);
      console.log(response.errors);
      if (!!response?.errors?.length)
        return this.setState({
          uploadingRegiZip: false,
          uploadErrors: response?.errors,
          regiZip: null,
        });
      alert.success('Registrations uploaded');
      this.setState({uploadingRegiZip: false, uploadRegiModalVisible: false});
      this.onRegiUploadClose()
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({uploadingRegiZip: false});
    }
  };

  onExpDateChange = (value, key) => {
    if (this.state.loading || this.state.uploadingRegiZip) return;
    this.setState({regiExpDate: value});
  };

  onNewVehicleClose = (prompt = true) => {
    if (this.savingNewVehicle) return;

    if (this.props.unsavedChanges && prompt)
      return notify({
        id: 'closeVehicleCreationModal',
        title: 'Close',
        icon: undefined,
        content: 'Are you sure you want to close?',
        primary: {
          label: 'No',
          onClick: () => null,
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.onNewVehicleClose(false),
        },
        closable: false,
        closeOnOutsideClick: true,
      });

    clearUnsavedChanges();

    this.setState({newVehicleModalVisible: false});
  };

  onVehicleSave = (save) => {
    this.savingNewVehicle = save;
  };

  render() {
    const {
      tab,
      page,
      loading,
      vehicles,
      sort,
      results,
      newVehicleModalVisible,
      uploadRegiModalVisible,
      lastNewVehicleUpdate,
      uploadingRegiZip,
      regiExpDate,
      uploadErrors,
      regiZip
    } = this.state;
    return (
      <Fragment>
        <VehiclesList
          hasPermission={createPermission()}
          results={results}
          tab={tab}
          tabs={this.tabs()}
          onTabChange={this.onTabChange}
          loading={loading}
          vehicles={vehicles}
          page={page}
          pages={this.pages()}
          onPage={this.onPage}
          sort={sort}
          onSort={this.onSort}
          onVehicle={this.onVehicle}
          onNewVehicle={this.onNewVehicle}
          onRegiUpload={this.onRegiUpload}
        />
        <NewVehicleContainer
          lastUpdate={lastNewVehicleUpdate}
          onLoad={this.onVehicleSave}
        >
          {({...props}) => (
            <NewVehicleModal
              {...props}
              visible={newVehicleModalVisible}
              onClose={this.onNewVehicleClose}
            />
          )}
        </NewVehicleContainer>
        <UploadRegiZipModal
          visible={uploadRegiModalVisible}
          onChange={this.changeRegiZip}
          onClose={this.onRegiUploadClose}
          onUpload={this.uploadRegiZip}
          regiZip={regiZip}
          uploadErrors={uploadErrors}
          uploading={uploadingRegiZip}
          regiExpDate={regiExpDate}
          onExpDateChange={this.onExpDateChange}
        />
      </Fragment>
    );
  }
}

export default withRouter(
  connect((state) => ({unsavedChanges: state.layout.unsavedChanges}))(
    VehiclesListContainer
  )
);
