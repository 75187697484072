// components
import Alert from '@matthahn/sally-ui/lib/components/Alert/Alert';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';
import Uploader from '@matthahn/sally-ui/lib/components/Uploader/Uploader';

// attributees
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput'


// local components
import Error from './components/Error';
import Errors from './components/Errors';
import UploadContainer from './components/UploadContainer';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const UploadRegiZipModal = ({
  onChange,
  onClose,
  onUpload,
  regiZip,
  uploadErrors,
  uploading,
  visible,
  regiExpDate,
  onExpDateChange
}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    title="Upload Registrations ZIP"
    buttonsRight={[{label: 'Upload', loading: uploading, onClick: onUpload}]}
  >
    {(Content) => (
      <Content>
        {!!uploadErrors.length && (
          <Row margin>
            <Column>
              <Alert type="error" flat rectangular>
                Following errors occurred while uploading:
              </Alert>
              <Errors>
                {uploadErrors.map((error) => (
                  <Error key={error}>{error}</Error>
                ))}
              </Errors>
            </Column>
          </Row>
        )}
        {!!regiZip && (
          <Alert type="info" flat rectangular>
            Selected ZIP: {regiZip.name}
          </Alert>
        )}
        <UploadContainer>
          <Uploader
            onChange={([file]) => onChange('regiZip')(file)}
            small
            loading={uploading}
            accept=".zip"
          />
        </UploadContainer>
        <Row margin>
        <Column>
          <AttributeInput
            errors={[]}
            value={regiExpDate}
            onChange={onExpDateChange}
            disabled={uploading}
          >
            {regiExpDate.label.default}
         </AttributeInput> 
         </Column>

        </Row>
      </Content>
    )}
  </Modal>
);

UploadRegiZipModal.propTypes = {
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onUpload: PropTypes.func,
  regiZip: PropTypes.object,
  uploadErrors: PropTypes.array,
  uploading: PropTypes.bool,
  visible: PropTypes.bool,
  regiExpDate: PropTypes.object,
  onExpDateChange: PropTypes.func
};

export default UploadRegiZipModal;
